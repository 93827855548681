import filter from 'lodash/filter';
import find from 'lodash/find';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import some from 'lodash/some';
import { INVOICE_STATUS } from '@domains/Invoicing/const/INVOICE_STATUS';
import { INVOICE_TYPES } from '../../const/INVOICE_TYPES';

// Temporary adapter until the final response of the invoice endpoint is defined
// When the new response is defined this adapter will be removed
// https://europcarmobility.atlassian.net/browse/SBCB-2060

const invoiceGetters = {
  globalInvoiceData: state => get(state, 'invoices.data.data'),

  invoiceData: (state, getters) => ({
    id: get(getters.globalInvoiceData, 'invoice.id'),
    uuid: get(getters.globalInvoiceData, 'invoice.uuid'),
    linkedInvoice: get(getters.globalInvoiceData, 'invoice.linkedInvoice'),
    serie: get(getters.globalInvoiceData, 'invoice.serie'),
    serieType: get(getters.globalInvoiceData, 'invoice.serieType'),
    number: get(getters.globalInvoiceData, 'invoice.number'),
    reference: get(getters.globalInvoiceData, 'invoice.reference'),
    status: get(getters.globalInvoiceData, 'invoice.status'),
    date: get(getters.globalInvoiceData, 'invoice.date'),
    dateStr: get(getters.globalInvoiceData, 'invoice.dateStr'),
    closedDateStr: get(getters.globalInvoiceData, 'invoice.status') === INVOICE_STATUS.closed
      ? get(getters.globalInvoiceData, 'invoice.dateStr')
      : null,
    dueDate: get(getters.globalInvoiceData, 'invoice.dueDate'),
    dueDateStr: get(getters.globalInvoiceData, 'invoice.dueDate'),
    preselectedBookingId: get(getters.globalInvoiceData, 'invoice.csBookingFk'),
    filename: get(getters.globalInvoiceData, 'invoice.fileName'),
    showPendingAmount: get(getters.globalInvoiceData, 'invoice.showPendingAmount'),
    taxesAmount: {
      value: get(getters.globalInvoiceData, 'invoice.taxesAmount.amount'),
      valueStr: get(getters.globalInvoiceData, 'invoice.taxesAmountStr'),
      currency: get(getters.globalInvoiceData, 'invoice.taxesAmount.currency'),
    },
    subtotalAmount: {
      value: get(getters.globalInvoiceData, 'invoice.subtotalAmount.amount'),
      valueStr: get(getters.globalInvoiceData, 'invoice.subtotalAmountStr'),
      currency: get(getters.globalInvoiceData, 'invoice.subtotalAmount.currency'),
    },
    totalAmount: {
      value: get(getters.globalInvoiceData, 'invoice.totalAmount.amount'),
      valueStr: get(getters.globalInvoiceData, 'invoice.totalAmountStr'),
      currency: get(getters.globalInvoiceData, 'invoice.totalAmount.currency'),
    },
    discountAmount: {
      value: get(getters.globalInvoiceData, 'invoice.discountAmount.amount'),
      valueStr: get(getters.globalInvoiceData, 'invoice.discountAmountStr'),
      currency: get(getters.globalInvoiceData, 'invoice.discountAmount.currency'),
    },
    totalToPayAmount: {
      value: get(getters.globalInvoiceData, 'invoice.totalToPayAmount.amount'),
      valueStr: get(getters.globalInvoiceData, 'invoice.totalToPayAmountStr'),
      currency: get(getters.globalInvoiceData, 'invoice.totalToPayAmount.currency'),
    },
    paidAmount: {
      value: get(getters.globalInvoiceData, 'invoice.paidAmount.amount'),
      valueStr: get(getters.globalInvoiceData, 'invoice.paidAmountStr'),
      currency: get(getters.globalInvoiceData, 'invoice.paidAmount.currency'),
    },
    pendingAmount: {
      value: get(getters.globalInvoiceData, 'invoice.pendingAmount.amount'),
      valueStr: get(getters.globalInvoiceData, 'invoice.pendingAmountStr'),
      currency: get(getters.globalInvoiceData, 'invoice.pendingAmount.currency'),
    },
    totalClAmount: {
      value: get(getters.globalInvoiceData, 'invoice.totalClAmount.amount'),
      valueStr: get(getters.globalInvoiceData, 'invoice.totalClAmountStr'),
      currency: get(getters.globalInvoiceData, 'invoice.totalClAmount.currency'),
    },
  }),

  invoiceId: (state, getters) => get(getters.invoiceData, 'id'),
  invoiceType: (state, getters) => get(getters.invoiceData, 'serieType'),
  invoiceStatus: (state, getters) => get(getters.invoiceData, 'status'),
  isDraftCreditNote: (state, getters) => get(getters.invoiceData, 'serieType') === INVOICE_TYPES.proforma
    && !!get(getters.invoiceData, 'linkedInvoice'),

  issuerData: (state, getters) => merge(
    pick(
      get(getters.globalInvoiceData, 'issuer'),
      ['name', 'fiscalNumber', 'addressStreet', 'addressCity', 'addressPhone', 'logoUrl'],
    ),
    {
      registrationNumber: get(getters.globalInvoiceData, 'issuerCompanyRegistrationNumber'),
    },
  ),

  customerData: (state, getters) => ({
    name: get(getters.globalInvoiceData, 'invoice.customerName'),
    street1: get(getters.globalInvoiceData, 'invoice.customerStreet1'),
    street2: get(getters.globalInvoiceData, 'invoice.customerStreet2'),
    city: get(getters.globalInvoiceData, 'invoice.customerCity'),
    postalCode: get(getters.globalInvoiceData, 'invoice.customerPostalCode'),
    province: get(getters.globalInvoiceData, 'invoice.customerProvince'),
    country: get(getters.globalInvoiceData, 'invoice.customerCountry'),
    phone: get(getters.globalInvoiceData, 'customer.customerPhone'),
    userId: get(getters.globalInvoiceData, 'invoice.userFk'),
    userUuid: get(getters.globalInvoiceData, 'customer.userUuid'),
    companyId: get(getters.globalInvoiceData, 'invoice.companyFk'),
    companyUuid: get(getters.globalInvoiceData, 'customer.companyUuid'),
    taxNumber: get(getters.globalInvoiceData, 'invoice.customerTaxNumber'),
    email: get(getters.globalInvoiceData, 'customer.customerEmail'),
  }),

  refundableBookingData: (state, getters) => map(get(getters.globalInvoiceData, 'refundableBookings'), booking => ({
    id: booking.bookingId,
    uuid: booking.bookingUuid,
    startStr: booking.start,
    endStr: booking.end,
    location: booking.location,
    vehicle: booking.vehicle,
    vehiclePlate: booking.vehiclePlate,
    user: booking.user,
  })),
  hasRefundableBookings: (state, getters) => !isEmpty(getters.refundableBookingData),

  bookingData: (state, getters) => map(get(getters.globalInvoiceData, 'bookings'), booking => ({
    id: booking.bookingId,
    uuid: booking.bookingUuid,
    startStr: booking.start,
    endStr: booking.end,
    location: booking.location,
    vehicle: booking.vehicle,
    vehiclePlate: booking.vehiclePlate,
    user: booking.user,
  })),
  hasBookings: (state, getters) => !isEmpty(getters.bookingData),

  serviceData: (state, getters) => map(get(getters.globalInvoiceData, 'invoice.invoiceLines'), service => ({
    id: service.id,
    linkedId: service.linkedInvoiceLineId,
    dateStr: service.date,
    bookingId: service.csBookingFk,
    serviceId: service.serviceFk,
    name: service.serviceName,
    units: service.units,
    description: service.description,
    amount: {
      value: service.amount.amount,
      valueStr: service.amountStr,
      currency: service.amount.currency,
    },
    netAmount: {
      value: service.lineNetAmount.amount,
      valueStr: service.lineNetAmountStr,
      currency: service.lineNetAmount.currency,
    },
    taxAmount: {
      value: service.lineTaxAmount.amount,
      valueStr: service.lineTaxAmountStr,
      currency: service.lineTaxAmount.currency,
    },
    grossAmount: {
      value: service.lineGrossAmount.amount,
      valueStr: service.lineGrossAmountStr,
      currency: service.lineGrossAmount.currency,
    },
  })),
  getServicesByBookingId: (state, getters) => bookingId => filter(getters.serviceData, { bookingId }),
  hasServiceLines: (state, getters) => !isEmpty(getters.serviceData),
  hasNotServiceLines: (state, getters) => isEmpty(getters.serviceData),
  hasServiceLinesWithBooking: (state, getters) => some(getters.serviceData, 'bookingId'),
  hasServiceLinesWithoutBooking: (state, getters) => some(getters.serviceData, { bookingId: null }),

  refundableServiceData: (state, getters) => map(get(getters.globalInvoiceData, 'invoice.refundableInvoiceLines'), service => ({
    id: service.id,
    dateStr: service.date,
    bookingId: service.csBookingFk,
    serviceId: service.serviceFk,
    name: service.serviceName,
    units: service.units,
    inversedUnits: service.inversedUnits,
    description: service.description,
    amount: {
      value: service.amount.amount,
      valueStr: service.amountStr,
      currency: service.amount.currency,
    },
    netAmount: {
      value: service.lineNetAmount.amount,
      valueStr: service.lineNetAmountStr,
      currency: service.lineNetAmount.currency,
    },
    taxAmount: {
      value: service.lineTaxAmount.amount,
      valueStr: service.lineTaxAmountStr,
      currency: service.lineTaxAmount.currency,
    },
    grossAmount: {
      value: service.lineGrossAmount.amount,
      valueStr: service.lineGrossAmountStr,
      currency: service.lineGrossAmount.currency,
      inversedValueStr: service.inversedLineGrossAmountStr,
    },
  })),
  getRefundableServicesByBookingId: (state, getters) => bookingId => filter(getters.refundableServiceData, { bookingId }),
  hasRefundableServiceLinesWithBooking: (state, getters) => some(getters.refundableServiceData, 'bookingId'),
  hasRefundableServiceLinesWithoutBooking: (state, getters) => some(getters.refundableServiceData, { bookingId: null }),

  draftServiceData: (state, getters) => map(getters.refundableServiceData, refundableService => {
    const draftService = find(getters.serviceData, { linkedId: refundableService.id });
    const isDraftService = !isEmpty(draftService);

    return {
      isToRefund: isDraftService,
      units: isDraftService ? draftService.units : refundableService.inversedUnits,
      linkedInvoiceLineId: refundableService.id,
    };
  }),

  paymentsData: (state, getters) => map(get(getters.globalInvoiceData, 'payments'), payment => ({
    id: payment.id,
    internal: payment.internal,
    dateStr: payment.dateStr,
    action: payment.action,
    status: payment.status,
    transactionRef: payment.transactionRef,
    provider: payment.paymentProvider,
    methodId: payment.paymentMethodId,
    methodName: payment.paymentMethod,
    description: payment.description,
    amount: {
      value: payment.paymentAmount.amount,
      valueStr: payment.amountStr,
      currency: payment.paymentAmount.currency,
    },
    uuid: payment.uuid,
  })),

  getPaymentById: (state, getters) => id => find(getters.paymentsData, { id }),

  hasPayments: (state, getters) => !isEmpty(getters.paymentsData),

  taxesData: (state, getters) => map(get(getters.globalInvoiceData, 'invoice.taxes'), tax => ({
    name: tax.name,
    vat: {
      value: tax.vat,
      valueStr: tax.vatStr,
    },
    amount: {
      value: tax.amount.amount,
      valueStr: tax.amountStr,
      currency: tax.amount.currency,
    },
  })),

  childInvoicesData: (state, getters) => map(get(getters.globalInvoiceData, 'invoice.childInvoices', []), child => ({
    ...pick(child, ['uuid', 'id', 'reference', 'serie', 'serieType', 'status', 'dateStr']),
    closedDateStr: get(child, 'status') === INVOICE_STATUS.closed ? child.dateStr : null,
    taxesAmount: {
      value: get(child, 'taxesAmount.amount'),
      valueStr: get(child, 'taxesAmountStr'),
      currency: get(child, 'taxesAmount.currency'),
    },
    subtotalAmount: {
      value: get(child, 'subtotalAmount.amount'),
      valueStr: get(child, 'subtotalAmountStr'),
      currency: get(child, 'subtotalAmount.currency'),
    },
    totalAmount: {
      value: get(child, 'totalAmount.amount'),
      valueStr: get(child, 'totalAmountStr'),
      currency: get(child, 'totalAmount.currency'),
    },
    discountAmount: {
      value: get(child, 'discountAmount.amount'),
      valueStr: get(child, 'discountAmountStr'),
      currency: get(child, 'discountAmount.currency'),
    },
    totalToPayAmount: {
      value: get(child, 'totalToPayAmount.amount'),
      valueStr: get(child, 'totalToPayAmountStr'),
      currency: get(child, 'totalToPayAmount.currency'),
    },
    paidAmount: {
      value: get(child, 'paidAmount.amount'),
      valueStr: get(child, 'paidAmountStr'),
      currency: get(child, 'paidAmount.currency'),
    },
    pendingAmount: {
      value: get(child, 'pendingAmount.amount'),
      valueStr: get(child, 'pendingAmountStr'),
      currency: get(child, 'pendingAmount.currency'),
    },
  })),

  draftCreditNoteUuid: (state, getters) => get(find(getters.childInvoicesData, { serieType: 'proforma' }), 'uuid'),

  creditNoteData: state => get(state, 'creditNote.data'),
  createdCreditNoteUuid: (state, getters) => get(getters.creditNoteData, 'uuid'),
};

export default invoiceGetters;
