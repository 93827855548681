import { createCustomStore, withScopes } from '@emobg/web-utils';
import get from 'lodash/get';
import { backOffice } from '@emobg/web-api-client';
import { clearErrors, toastError } from '@/utils';

import getters from './invoiceGetters';

const {
  postInvoice,
  putCloseInvoice,
  putSyncCustomerAddress,
  getInvoicePdf,
  putCustomerInvoice,
  postInvoiceLine,
  putInvoiceLine,
  deleteInvoiceLine,
  postInvoicePayment,
  putInvoicePayment,
  deleteInvoicePayment,
  postRefundInvoicePayment,
  postRefundInvoicePaymentTransaction,
  putRetryInvoicePayment,
  postCreditNote,
  getInvoiceByUuid,
  postSendInvoiceByEmail,
  patchCreditNote,
  postDraftCreditNote,
  postSendInvoicePdf,
  getInvoiceDownloadUrl,
} = backOffice.invoicing;

export const scopes = {
  invoices: 'invoices',
  invoicePdf: 'invoicePdf',
  invoiceMedia: 'invoiceMedia',
  serviceLine: 'serviceLine',
  invoicePayments: 'invoicePayments',
  customerAddress: 'customerAddress',
  creditNote: 'creditNote',
  sap: 'sap',
  sapList: 'sapList',
  downloadSap: 'downloadSap',
};

const invoiceModule = createCustomStore(({ runAsyncFlow }) => withScopes(scopes, {
  getters,

  mutations: {
    setError(state, payload) {
      toastError.bind(this)(state, payload, [
        null,
        scopes.invoices,
        scopes.invoicePdf,
        scopes.serviceLine,
        scopes.invoicePayments,
        scopes.customerAddress,
        scopes.creditNote,
        scopes.sap,
        scopes.sapList,
        scopes.downloadSap,
      ]);
    },
    setRawInvoicePdf(state, rawInvoicePdf) {
      state.invoicePdf.data = rawInvoicePdf;
    },
    setSapListData(state, data) {
      state.sapList.data = data;
    },
    setDownloadableSapFile(state, data) {
      state.downloadSap.data = data;
    },
    clearErrors,
  },

  actions: {
    async postInvoice({ commit }, invoice) {
      await runAsyncFlow(commit, {
        request: postInvoice,
        params: [invoice],
      });
    },

    async exportSap({ commit }, sapRequest) {
      await runAsyncFlow(commit, {
        request: backOffice.sap.exportSAP,
        params: [sapRequest],
        scope: scopes.sap,
      });
    },
    async getSapFilesList({ commit }, operatorUuid) {
      await runAsyncFlow(commit, {
        request: backOffice.sap.getSapListRequest,
        params: [operatorUuid],
        scope: scopes.sapList,
      }).then(result => {
        commit('setSapListData', get(result, 'data'));
      });
    },

    async requestExportSapFile({ commit }, data) {
      const operatorUuid = get(data, 'operatorUuid');
      const query = get(data, 'query');
      await runAsyncFlow(commit, {
        request: backOffice.sap.postSapFileRequest,
        params: [operatorUuid, query],
        scopes: scopes.sap,
      });
    },
    async downloadSapFile({ commit }, data) {
      await runAsyncFlow(commit, {
        request: backOffice.sap.requestDownloadSAPFile,
        params: [data],
        scopes: scopes.downloadSap,
      }).then(result => {
        commit('setDownloadableSapFile', get(result, 'data'));
      });
    },
    async putCloseInvoice({ commit }, invoiceId) {
      await runAsyncFlow(commit, {
        request: putCloseInvoice,
        params: [invoiceId],
      });
    },

    async putSyncCustomerAddress({ commit }, invoiceId) {
      await runAsyncFlow(commit, {
        request: putSyncCustomerAddress,
        params: [invoiceId],
        scope: scopes.customerAddress,
      });
    },

    async getInvoicePdf({ commit }, invoiceId) {
      await runAsyncFlow(commit, {
        request: getInvoicePdf,
        params: [invoiceId],
        scope: scopes.invoicePdf,
      }).then(rawInvoicePdf => {
        commit('setRawInvoicePdf', rawInvoicePdf);
      });
    },

    async putCustomerInvoice({ commit }, { invoiceId, payload }) {
      await runAsyncFlow(commit, {
        request: putCustomerInvoice,
        params: [invoiceId, payload],
      });
    },

    async postInvoiceLine({ commit }, payload) {
      await runAsyncFlow(commit, {
        request: postInvoiceLine,
        params: [payload],
        scope: scopes.serviceLine,
      });
    },

    async putInvoiceLine({ commit }, { invoiceLineId, payload }) {
      await runAsyncFlow(commit, {
        request: putInvoiceLine,
        params: [invoiceLineId, payload],
        scope: scopes.serviceLine,
      });
    },

    async deleteInvoiceLine({ commit }, { invoiceId, invoiceLineId }) {
      await runAsyncFlow(commit, {
        request: deleteInvoiceLine,
        params: [invoiceId, invoiceLineId],
        scope: scopes.serviceLine,
      });
    },

    async postInvoicePayment({ commit }, { invoiceId, payload }) {
      await runAsyncFlow(commit, {
        request: postInvoicePayment,
        params: [invoiceId, payload],
        scope: scopes.invoicePayments,
      });
    },

    async putInvoicePayment({ commit }, { paymentId, payload }) {
      await runAsyncFlow(commit, {
        request: putInvoicePayment,
        params: [paymentId, payload],
        scope: scopes.invoicePayments,
      });
    },

    async deleteInvoicePayment({ commit }, paymentId) {
      await runAsyncFlow(commit, {
        request: deleteInvoicePayment,
        params: [paymentId],
        scope: scopes.invoicePayments,
      });
    },

    async postRefundInvoicePayment({ commit }, { paymentId, payload }) {
      await runAsyncFlow(commit, {
        request: postRefundInvoicePayment,
        params: [paymentId, payload],
        scope: scopes.invoicePayments,
      });
    },

    async postRefundInvoicePaymentTransaction({ commit }, { transactionUuid, payload }) {
      await runAsyncFlow(commit, {
        request: postRefundInvoicePaymentTransaction,
        params: [transactionUuid, payload],
        scope: scopes.invoicePayments,
      });
    },

    async putRetryInvoicePayment({ commit }, { paymentId, payload }) {
      await runAsyncFlow(commit, {
        request: putRetryInvoicePayment,
        params: [paymentId, payload],
        scope: scopes.invoicePayments,
      });
    },

    async postCreditNote({ commit }, { invoiceUuid }) {
      await runAsyncFlow(commit, {
        request: postCreditNote,
        params: [invoiceUuid],
        scope: scopes.creditNote,
      });
    },

    async getInvoiceByUuid({ commit }, { invoiceUuid }) {
      await runAsyncFlow(commit, {
        request: getInvoiceByUuid,
        params: [invoiceUuid],
        scope: scopes.invoices,
      });
    },

    async postSendInvoiceByEmail({ commit }, { invoiceId, payload }) {
      await runAsyncFlow(commit, {
        request: postSendInvoiceByEmail,
        params: [invoiceId, payload],
        scope: scopes.invoices,
      });
    },

    async patchCreditNote({ commit }, { invoiceUuid, payload }) {
      await runAsyncFlow(commit, {
        request: patchCreditNote,
        params: [invoiceUuid, payload],
        scope: scopes.creditNote,
      });
    },

    async postDraftCreditNote({ commit }, { invoiceUuid }) {
      await runAsyncFlow(commit, {
        request: postDraftCreditNote,
        params: [invoiceUuid],
        scope: scopes.creditNote,
      });
    },

    async postSendInvoicePdf({ commit }, { invoiceId, payload }) {
      await runAsyncFlow(commit, {
        request: postSendInvoicePdf,
        params: [invoiceId, payload],
        scope: scopes.invoicePdf,
      });
    },

    async getInvoiceDownloadUrl({ commit }, invoiceUuid) {
      await runAsyncFlow(commit, {
        request: getInvoiceDownloadUrl,
        params: [invoiceUuid],
        scope: scopes.invoiceMedia,
      });
    },
  },
}));

export default invoiceModule;
